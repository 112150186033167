/* Handle Scrollbar */
::-webkit-scrollbar {
  width: 5px;
}

/* Handle thumb*/
::-webkit-scrollbar-thumb {
  background-color: #000000;
  border-radius: 8px;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* Define the width of the scrollbar */
/* You may need to use -moz-scrollbar-width for Firefox */
/* Also, note that the values are 'thin', 'auto', or 'none' */
/* 'none' hides the scrollbar */
/* You can also use px for width */
/* The radius of the border is set to match the desired border-radius */
/* Webkit is used for Chrome, Safari, etc. */
/* moz is used for Firefox */
/* You can adjust the color and other properties as needed */
/* Note that these styles may not work in all browsers */

/* For Firefox */
/* Thin scrollbar width */
/* Auto hiding scrollbar */
/* Custom scrollbar color */
/* Custom border-radius */
* {
  scrollbar-width: thin;
  scrollbar-color: #d7d6d6 #f1f1f1;
}

/* For Firefox */
/* Thin scrollbar width */
/* Auto hiding scrollbar */
/* Custom scrollbar color */
/* Custom border-radius */
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* For Firefox */
/* Thin scrollbar width */
/* Auto hiding scrollbar */
/* Custom scrollbar color */
/* Custom border-radius */
*::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #f1f1f1 !important;
}

/* For Firefox */
/* Thin scrollbar width */
/* Auto hiding scrollbar */
/* Custom scrollbar color */
/* Custom border-radius */
*::-webkit-scrollbar-thumb {
  background-color: #eaecf0;
  border-radius: 8px;
}
